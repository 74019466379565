<template>
  <main class="main">
    <nav class="breadcrumb-nav border-0 mb-0">
      <div class="container">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/">Home</router-link>
          </li>
          <li class="breadcrumb-item active">Reset Password</li>
        </ol>
      </div>
    </nav>

    <div class="page-content pb">
      <ResetPasswordComponent />
    </div>
  </main>
</template>
<script>
// import cmsBlockSimple from "@/esf_weert_mobilesupplies/core/components/core/BlockSimple";
import ResetPasswordComponent from "@/esf_weert_mobilesupplies/core/components/partial/auth/ResetPasswordComponent.vue";
export default {
  name: "Reset",
  components: {
    // cmsBlockSimple,
    ResetPasswordComponent,
  },
  // computed: {
  //     headerTopRightBlock() {
  //         return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
  //             "header_top_right_block"
  //         );
  //     },
  // },
  metaInfo() {
    return {
      title: this.$t("reset_password"),
    };
  },
};
</script>
